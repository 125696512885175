<template>
    <Transition name="fade">
        <div
            v-if="$store.state.cookie.isCookieBannerVisible"
            class="fixed bottom-0 left-0 lg:bottom-8 lg:left-8 w-full lg:w-[calc(100%-4rem)] xl:w-8/12 2xl:w-7/12 3xl:w-6/12 theme-dark bg-black px-4 py-8 sm:p-8 lg:py-12 lg:px-12 rounded-t lg:rounded-lg text-white"
        >
            <span class="font-FibraOne-UltraBold text-lg lg:text-xl mb-3">Ez az oldal sütiket használ</span>
            <div class="">
                Weboldalunk a használat megkönnyítése és működtetése céljából, valamint a felhasználói élmény
                javításának érdekében analitikai sütiket használ. További információk az
                <NuxtLink to="/adatkezelesi-nyilatkozat" class="underline">Adatvédelmi nyilatkozatban.</NuxtLink>
            </div>
            <Transition name="fade">
                <div
                    v-if="areCheckboxesVisible"
                    class="flex flex-col sm:flex-row flex-wrap gap-x-8 gap-y-4 mt-8 cookie-consent-checkboxes"
                >
                    <div class="cookie-option">
                        <div class="cookie-option__tooltip">
                            Ezek a cookie-k a szolgáltatás működéséhez szükségesek, beleértve a biztonságot és a csalás
                            megelőzését.
                        </div>
                        <Checkbox id="consent-necessary" v-model="model.necessary" disabled :binary="true" />
                        <label for="consent-necessary">Kötelező sütik</label>
                    </div>
                    <div class="cookie-option">
                        <div class="cookie-option__tooltip">
                            Ezek a cookie-k segítenek megérteni, hogyan lépsz kapcsolatba az oldallal, és milyen típusú
                            tartalmak értékesek a számodra.
                        </div>
                        <Checkbox id="consent-analytics" v-model="model.statistics" :binary="true" />
                        <label for="consent-analytics">Statisztikai sütik</label>
                    </div>
                    <div class="cookie-option">
                        <div class="cookie-option__tooltip">
                            Ezek a cookie-k segítenek megjegyezni a beállításaidat, például ország vagy nyelv, hogy
                            személyre szabhassuk a webhelyen történő következő látogatásaidat.
                        </div>
                        <Checkbox id="consent-personalization" v-model="model.preferences" :binary="true" />
                        <label for="consent-personalization">Preferenciák</label>
                    </div>
                    <div class="cookie-option">
                        <div class="cookie-option__tooltip">
                            Ezeket a sütiket arra használjuk, hogy az érdeklődési köröd alapján hirdetéseket és
                            marketingkommunikációt jelenítsünk meg más webhelyeken.
                        </div>
                        <Checkbox id="consent-marketing" v-model="model.marketing" :binary="true" />
                        <label for="consent-marketing">Marketing</label>
                    </div>
                </div>
            </Transition>
            <div class="flex flex-col gap-y-6">
                <div
                    class="flex flex-col sm:flex-row justify-center sm:justify-start items-center gap-x-8 gap-y-4 mt-10"
                >
                    <Button label="Összes elfogadása" class="w-full sm:w-auto" @click.native="acceptAll" />

                    <Button
                        v-if="!areCheckboxesVisible"
                        label="További lehetőségek"
                        class="p-button-outlined w-full sm:w-auto"
                        @click.native="areCheckboxesVisible = true"
                    />

                    <Button
                        v-if="areCheckboxesVisible"
                        label="Kiválasztottak elfogadása"
                        class="p-button-outlined w-full sm:w-auto"
                        @click.native="updateSelection"
                    />

                    <Button
                        v-if="areCheckboxesVisible"
                        label="Elutasítás"
                        class="p-button-outlined w-full sm:w-auto"
                        @click.native="denyAll"
                    />
                </div>
            </div>
            <button class="close-button z-10" @click="$store.dispatch('cookie/closeBanner')">
                <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path
                        d="M12.7099 11.2902C12.8982 11.4785 13.004 11.7339 13.004 12.0002C13.004 12.2665 12.8982 12.5219 12.7099 12.7102C12.5216 12.8985 12.2662 13.0043 11.9999 13.0043C11.7336 13.0043 11.4782 12.8985 11.2899 12.7102L6.99994 8.41019L2.70994 12.7102C2.52164 12.8985 2.26624 13.0043 1.99994 13.0043C1.73364 13.0043 1.47825 12.8985 1.28994 12.7102C1.10164 12.5219 0.99585 12.2665 0.99585 12.0002C0.99585 11.7339 1.10164 11.4785 1.28994 11.2902L5.58994 7.00019L1.28994 2.71019C1.10164 2.52188 0.99585 2.26649 0.99585 2.00019C0.99585 1.73388 1.10164 1.47849 1.28994 1.29019C1.47825 1.10188 1.73364 0.996094 1.99994 0.996094C2.26624 0.996094 2.52164 1.10188 2.70994 1.29019L6.99994 5.59019L11.2899 1.29019C11.4782 1.10188 11.7336 0.996094 11.9999 0.996094C12.2662 0.996094 12.5216 1.10188 12.7099 1.29019C12.8982 1.47849 13.004 1.73388 13.004 2.00019C13.004 2.26649 12.8982 2.52188 12.7099 2.71019L8.40994 7.00019L12.7099 11.2902Z"
                        fill="#fff"
                    />
                </svg>
            </button>
        </div>
    </Transition>
</template>

<script>
export default {
    name: 'CookieBanner',
    data() {
        return {
            areCheckboxesVisible: false,
            model: {
                necessary: true,
                statistics: true,
                marketing: true,
                preferences: true,
            },
        };
    },
    watch: {
        '$store.state.cookie.isCookieBannerVisible'(value) {
            // Load saved values from local storage every time the banner opens
            if (value) {
                const savedConsent = JSON.parse(localStorage.getItem('consent'));

                if (!savedConsent) {
                    return;
                }

                // At this point we know that the user is not setting their preferences for the first time,
                // they want to update them, so we display the checkboxes by default
                this.areCheckboxesVisible = true;

                // restore checkbox states
                this.model.statistics = !!savedConsent?.statistics;
                this.model.marketing = !!savedConsent?.marketing;
                this.model.preferences = !!savedConsent?.preferences;
            }
        },
    },
    mounted() {
        // If no stored consent preferences are found, open the banner
        const savedConsent = JSON.parse(localStorage.getItem('consent'));

        if (!savedConsent) {
            setTimeout(() => {
                this.$store.dispatch('cookie/openBanner');
            }, 1000);
        }
    },
    methods: {
        acceptAll() {
            this.model.statistics = true;
            this.model.marketing = true;
            this.model.preferences = true;
            this.updateSelection();
        },
        denyAll() {
            this.model.statistics = false;
            this.model.marketing = false;
            this.model.preferences = false;
            this.updateSelection();
        },
        updateSelection() {
            // Cookie consent types by categories

            // Necessary
            // - security_storage, functionality_storage
            // since we classify them as mandatory, we do not update their value in the payload

            // Statistics
            // - analytics_storage

            // Preferences
            // - personalization_storage

            // Marketing
            // - ad_storage, ad_user_data, ad_personalization

            // Create a payload from the preference input values consumable by Google Tag Manager
            const payload = {};

            const { statistics, preferences, marketing } = this.model;

            // payload.security_storage = necessary ? 'granted' : 'denied';
            // payload.functionality_storage = necessary ? 'granted' : 'denied';
            payload.analytics_storage = statistics ? 'granted' : 'denied';
            payload.personalization_storage = preferences ? 'granted' : 'denied';
            payload.ad_storage = marketing ? 'granted' : 'denied';
            payload.ad_user_data = marketing ? 'granted' : 'denied';
            payload.ad_personalization = marketing ? 'granted' : 'denied';

            // Update Google Tag Manager with the selected preferences
            this.$gtag('consent', 'update', payload);

            localStorage.setItem('consent', JSON.stringify(this.model));

            setTimeout(() => {
                this.$store.dispatch('cookie/closeBanner');
            }, 100);
        },
    },
};
</script>

<style>
.close-button {
    @apply absolute w-6 h-6 top-4 right-4 transition-all duration-[300ms];

    svg {
        @apply absolute w-4 h-4 top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2;

        path {
            transition: fill 0.3s;
        }
    }
}

.cookie-option {
    @apply flex relative;
    @media (hover: hover) {
        &:hover {
            .cookie-option__tooltip {
                @apply opacity-100;
            }
        }
    }
}

.cookie-option__tooltip {
    @apply absolute top-0 left-0 -mt-4 -translate-y-full w-[23rem] rounded-lg p-2 bg-white text-black text-sm pointer-events-none;
    @apply opacity-0 transition-opacity;
    &::before {
        content: '';
        @apply inline-block absolute bottom-0 w-0 h-0 left-10;
        border-style: solid;
        border-width: 0 8px 9px 8px;
        border-color: transparent transparent #ebebeb transparent;
        transform: translateY(100%) rotate(180deg);
    }
}
</style>
