export const state = () => ({
    quote: {},
    promotions: [],
    highlightedShows: [],
    scheduleByDays: [],
    shows: [],
    articles: [],
    siteContents: [],
    searchLabels: {
        shows: 'Műsorok',
        hosts: 'Műsorvezetők',
        articles: 'Egyéb',
        programs: 'Adások',
    },
});

export const mutations = {
    setQuote(state, payload) {
        state.quote = payload;
    },
    setHighlightedShows(state, payload) {
        state.highlightedShows = payload;
    },
    setPromotions(state, payload) {
        state.promotions = payload;
    },
    setScheduleByDays(state, payload) {
        state.scheduleByDays = payload;
    },
    setShows(state, payload) {
        state.shows = payload;
    },
    setArticles(state, payload) {
        state.articles = payload;
    },
    setSiteContents(state, payload) {
        state.siteContents = payload;
    },
};
