import Vue from 'vue';

// The complete list of icons can be found here: https://materialdesignicons.com/

import MenuIcon from 'vue-material-design-icons/Menu.vue';
import OpenInNew from 'vue-material-design-icons/OpenInNew.vue';
import Play from 'vue-material-design-icons/Play.vue';
import Pause from 'vue-material-design-icons/Pause.vue';
import Video from 'vue-material-design-icons/Video.vue';
import Update from 'vue-material-design-icons/Update.vue';
import ArrowLeft from 'vue-material-design-icons/ArrowLeft.vue';
import ArrowRight from 'vue-material-design-icons/ArrowRight.vue';
import Magnify from 'vue-material-design-icons/Magnify.vue';
import Close from 'vue-material-design-icons/Close.vue';
import Download from 'vue-material-design-icons/Download.vue';
import Facebook from 'vue-material-design-icons/Facebook.vue';
import Soundcloud from 'vue-material-design-icons/Soundcloud.vue';
import Youtube from 'vue-material-design-icons/Youtube.vue';
import Instagram from 'vue-material-design-icons/Instagram.vue';
import Twitch from 'vue-material-design-icons/Twitch.vue';
import Spotify from 'vue-material-design-icons/Spotify.vue';

Vue.component('MenuIcon', MenuIcon);
Vue.component('OpenInNewIcon', OpenInNew);
Vue.component('PlayIcon', Play);
Vue.component('PauseIcon', Pause);
Vue.component('VideoIcon', Video);
Vue.component('UpdateIcon', Update);
Vue.component('ArrowRightIcon', ArrowRight);
Vue.component('ArrowLeftIcon', ArrowLeft);
Vue.component('MagnifyIcon', Magnify);
Vue.component('CloseIcon', Close);
Vue.component('DownloadIcon', Download);
Vue.component('FacebookIcon', Facebook);
Vue.component('SoundcloudIcon', Soundcloud);
Vue.component('YoutubeIcon', Youtube);
Vue.component('InstagramIcon', Instagram);
Vue.component('TwitchIcon', Twitch);
Vue.component('SpotifyIcon', Spotify);
