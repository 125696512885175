<template>
    <client-only>
        <div
            class="plyr-wrapper fixed flex items-center bg-black lg:rounded-md shadow-lg left-1/2 -translate-x-1/2"
            :class="{
                'plyr-wrapper--folded': !$store.state.player.playingProgram,
                'plyr-wrapper--unfolded': $store.state.player.playingProgram,
                'plyr-wrapper--popup w-full left-0 bottom-0 px-0 sm:px-12': isInPopupPlayer,
                'translate-y-[calc(100%+1rem)]': !$store.state.player.isVisible,
                'channel-playing': !!$store.state.player.playingChannelName,
                'transition-all': isInitialFadeUpTransitionOver,
                'transition-transform': !isInitialFadeUpTransitionOver,
            }"
        >
            <div
                v-if="!isInPopupPlayer"
                class="plyr__track-badge absolute lg:relative left-4 lg:left-0 flex items-center z-10"
            >
                <ImageBadge
                    mobile-size="xs"
                    desktop-size="xs"
                    :alt="trackData.title"
                    :color="trackData.color"
                    :image="trackData.image"
                    :is-rectangle="/(jazzygroove|jazzysoul|jazzycool)/.test($store.state.player.playingChannelName)"
                />
                {{/* condition above to be extended with program data */}}
                <div class="ml-3 sm:ml-4">
                    <div v-if="trackData.description" class="text-xs text-grey-300" :title="trackData.description">
                        <LiveBadge
                            v-if="$store.state.player.playingChannelName === 'jazzylive'"
                            pulse
                            :text="trackData.description"
                            class="plyr__track-description"
                        />
                        <span v-else class="plyr__track-description">
                            {{ trackData.description }}
                        </span>
                    </div>
                    <div v-if="trackData.title" :title="trackData.title" class="plyr__track-title">
                        {{ trackData.title }}
                    </div>
                </div>
            </div>

            {{/* Rewind & Forward buttons */}}
            <Transition name="fade-opacity">
                <button
                    v-if="$device.isMobileOrTablet && $store.state.player.playingProgram"
                    class="plyr__seek-button right-[calc(50%+50px)] sm:right-52"
                    @click="rewind"
                >
                    <Rewind15 fill-color="#FFF" :size="18" />
                </button>
            </Transition>
            <Transition name="fade-opacity">
                <button
                    v-if="$device.isMobileOrTablet && $store.state.player.playingProgram"
                    class="plyr__seek-button right-[calc(50%-90px)] sm:right-24"
                    @click="forward"
                >
                    <FastForward15 fill-color="#FFF" :size="18" />
                </button>
            </Transition>

            <vue-plyr ref="mainPlayer" :options="options">
                <audio controls playsinline></audio>
            </vue-plyr>
            <button
                v-if="$device.isDesktop && !isInPopupPlayer"
                class="absolute right-5 top-1/2 -translate-y-1/2 flex items-center transition-colors text-grey-325 hover:text-white hidden sm:inline-block"
                :class="{ 'opacity-50 pointer-events-none': hasChildWindow }"
                :disabled="hasChildWindow"
                @click="openPlayerPopupWindow"
            >
                <OpenInNewIcon class="block" />
            </button>
        </div>
    </client-only>
</template>

<script>
import FastForward15 from 'vue-material-design-icons/FastForward15.vue';
import Rewind15 from 'vue-material-design-icons/Rewind15.vue';
import ImageBadge from '../../UI/ImageBadge';

export default {
    name: 'MainAudioPlayer',
    components: {
        ImageBadge,
        FastForward15,
        Rewind15,
    },
    data() {
        return {
            isInitialFadeUpTransitionOver: false,
            hasChildWindow: false,
        };
    },
    computed: {
        isInPopupPlayer() {
            return this.$route.fullPath === '/player';
        },
        trackData() {
            const data = {};

            if (this.$store.state.player.playingChannelName) {
                if (this.$store.state.player.playingChannelName === 'jazzylive') {
                    if (this.$store.state.channel.jazzy.now_playing.song.artist === 'Jazzy') {
                        const _show = this.$store.state.channel.currentJazzyLiveShow.show;
                        data.title = _show.title;
                        data.description = 'Élő';
                        data.image = _show.cover_image;
                        data.color = _show.color;
                    } else {
                        const _song = this.$store.state.channel.jazzy.now_playing.song;
                        data.title = _song.title;
                        data.description = _song.artist;
                        data.image = _song.art;
                    }
                } else {
                    const _song =
                        this.$store.state.channel[this.$store.state.player.playingChannelName].now_playing.song;
                    data.title = _song.title;
                    data.description = _song.artist;
                    data.image = _song.art;
                }
            } else if (this.$store.state.player.playingProgram) {
                const _program = this.$store.state.player.playingProgram;
                data.title = _program.title;
                data.description = _program.artist;
                data.image = _program.image;
                data.color = _program.color;
            }

            if (Object.keys(data).length) {
                this.setMediaMeta(data.title, data.description, data.image);
            }

            return data;
        },
        options() {
            let _controls = ['play-large', 'play', 'progress', 'current-time', 'pip', 'fullscreen'];

            if (this.$device.isDesktop) {
                _controls = [..._controls, 'mute', 'volume'];
            }

            return {
                controls: _controls,
                keyboard: { focused: true, global: true },
            };
        },
    },
    watch: {
        '$store.state.player.isPlaying'() {
            setTimeout(() => {
                this.isInitialFadeUpTransitionOver = true;
            }, 500);
        },
    },
    created() {
        this.$nuxt.$on('pauseMainPlayer', () => {
            this.$refs.mainPlayer.player.pause();
        });
        this.$nuxt.$on('playMainPlayer', () => {
            this.$refs.mainPlayer.player.play();
        });
        this.$nuxt.$on('toggleMainPlayerPlayState', () => {
            this.$refs.mainPlayer.player.togglePlay();
        });
        this.$nuxt.$on('openPopupPlayer', () => {
            this.openPlayerPopupWindow();
        });
        this.$nuxt.$on('closePopupPlayer', () => {
            this.hasChildWindow = false;
        });
        this.$nuxt.$on('updateMainPlayerStreamUrl', (streamUrl) => {
            if (!streamUrl) {
                console.error('Error: stream url has not been sent to player');
            }

            this.$refs.mainPlayer.player.source = {
                type: 'audio',
                sources: [
                    {
                        src: streamUrl,
                        type: 'audio/mp3',
                    },
                ],
            };

            this.$store.commit('player/setIsVisible', true);
        });
    },
    mounted() {
        setTimeout(() => {
            this.$refs.mainPlayer.player.on('playing', () => {
                this.$store.commit('player/setIsPlaying', true);
                // if the player is in the parent window, stop playback in popup
                if (window.childWindowHandler) {
                    window.childWindowHandler.postMessage('pause');
                }
                // vice versa
                if (window.opener) {
                    window.opener.$nuxt.$emit('pauseMainPlayer');
                    if (window.program && window.currentPlayerTime) {
                        this.$refs.mainPlayer.player.currentTime = window.currentPlayerTime;
                        window.currentPlayerTime = null;
                    }
                }
            });
            this.$refs.mainPlayer.player.on('pause', () => {
                this.$store.commit('player/setIsPlaying', false);
            });
        }, 50);
    },
    methods: {
        rewind() {
            this.$refs.mainPlayer.player.rewind(15);
        },
        forward() {
            this.$refs.mainPlayer.player.forward(15);
        },
        openPlayerPopupWindow() {
            window.childWindowHandler = window.open(
                '/player',
                'popup',
                'width=730,height=500,scrollbars=no,resizable=no'
            );

            if (this.$store.state.player.playingProgram) {
                window.childWindowHandler.program = this.$store.state.player.playingProgram;
                window.childWindowHandler.currentPlayerTime = this.$refs.mainPlayer.player.currentTime;
            }

            this.hasChildWindow = true;
            this.$refs.mainPlayer.player.pause();
        },
        setMediaMeta(title, artist, artwork) {
            const coverImage =
                artwork || require('~/assets/images/placeholders/jazzy-thumbnail-fallback_transparent.png');

            // yes I know, plyr has a mediaMetadata option too, but it
            // didn't work for me for some reason, so I'm sticking with the native api
            if ('mediaSession' in navigator) {
                navigator.mediaSession.metadata = new MediaMetadata({
                    title,
                    artist,
                    artwork: [{ src: coverImage, type: 'image/png' }],
                });
            }
        },
    },
};
</script>

<style>
#plyr-airplay {
    @apply hidden;
}
</style>
