<template>
    <button v-show="isVisible" class="icon" :class="{ active }">
        <div class="circle">
            <span></span>
            <span></span>
            <span></span>
        </div>
        <div class="cross">
            <span></span>
            <span></span>
        </div>
    </button>
</template>

<script>
export default {
    name: 'AnimatingSearchButton',
    props: {
        active: {
            type: Boolean,
            required: true,
        },
    },
    data() {
        return {
            isVisible: false,
        };
    },
    mounted() {
        this.isVisible = true;
    },
};
</script>

<style scoped>
.icon,
.icon *::before,
.icon *::after {
    --animating-search-button-width: 20px;
    --animating-search-button-ease-function: cubic-bezier(0.5, 0, 0, 1);
    --animating-search-button-color: #fff;
    --animating-search-button-thickness: 2px;
}

.icon {
    display: inline-block;
    position: relative;
    width: var(--animating-search-button-width);
    height: var(--animating-search-button-width);
    min-width: 20px;
    margin: 0 calc(var(--animating-search-button-width) / 2);
    transform: rotate(-45deg);

    &::before {
        content: '';
        display: inline-block;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        width: 40px;
        height: 40px;
        border-radius: 50%;
    }

    span {
        background: var(--animating-search-button-color);
        background: none;
        position: absolute;
    }

    .circle {
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;

        span {
            position: absolute;
            width: 50%;
            overflow: hidden;

            &::before {
                content: '';
                border-radius: 50%;
                width: 200%;
                border: var(--animating-search-button-thickness) solid transparent;
                transform: rotate(45deg);
                box-sizing: border-box;

                /* position */
                position: absolute !important;
            }

            &:nth-of-type(1) {
                left: 0;
                top: 0;
                height: 100%;

                &::before {
                    top: 0;
                    bottom: 0;
                    left: 0;
                    border-bottom-color: var(--animating-search-button-color);
                    border-left-color: var(--animating-search-button-color);
                    transition: transform 0.3s cubic-bezier(0, 0, 0, 1) 0.3s;

                    /* position */
                    position: absolute !important;
                }
            }

            &:nth-of-type(2) {
                right: 0;
                top: 0;
                height: 50%;

                &::before {
                    top: 0;
                    right: 0;
                    height: 200%;
                    border-top-color: var(--animating-search-button-color);
                    transition: transform 0.2s cubic-bezier(0.7, 0, 1, 1) 0.1s;
                }
            }

            &:nth-of-type(3) {
                right: 0;
                height: 50%;
                bottom: 0;

                &::before {
                    bottom: 0;
                    right: 0;
                    height: 200%;
                    border-right-color: var(--animating-search-button-color);
                    transition: transform 0.6s var(--animating-search-button-ease-function) 0.1s;

                    /* position */
                    position: absolute !important;
                }
            }
        }
    }

    .cross {
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;

        span {
            &::before {
                content: '';
                background: var(--animating-search-button-color);
                top: 0;
                right: 0;
                bottom: 0;
                left: 0;
            }

            &:nth-of-type(1) {
                left: 50%;
                width: var(--animating-search-button-thickness);
                top: 0;
                bottom: 0;
                transform: translate3d(-50%, calc(var(--animating-search-button-width) / 3.5), 0);
                overflow: hidden;
                transition: transform 0.3s cubic-bezier(0.7, 0, 1, 1);

                &::before {
                    transform-origin: bottom;
                    transform: translate3d(
                        0,
                        calc(
                            100% -
                                calc(
                                    var(--animating-search-button-width) / 3.5 +
                                        var(--animating-search-button-thickness)
                                )
                        ),
                        0
                    );
                    transition: transform 0.5s var(--animating-search-button-ease-function);

                    /* position */
                    position: absolute !important;
                }
            }

            &:nth-of-type(2) {
                top: 50%;
                height: var(--animating-search-button-thickness);
                left: 0;
                right: 0;
                transform: translate3d(0, -50%, 0);

                &::before {
                    transform-origin: right;
                    transform: scale3d(0, 1, 1);
                    transition: transform 0.3s cubic-bezier(0.6, 0, 1, 1);

                    /* position */
                    position: absolute !important;
                }
            }
        }
    }

    &.active {
        .circle {
            span:nth-of-type(1) {
                &::before {
                    transform: rotate(225deg);
                    transition: transform 0.2s cubic-bezier(0.7, 0, 1, 1);

                    /* position */
                    position: absolute !important;
                }
            }

            span:nth-of-type(2) {
                &::before {
                    transform: rotate(135deg);
                    transition: transform 0.3s cubic-bezier(0, 0, 0, 1) 0.2s;

                    /* position */
                    position: absolute !important;
                }
            }

            span:nth-of-type(3) {
                &::before {
                    transform: rotate(-45deg);
                    transition: transform 0.5s var(--animating-search-button-ease-function) 0.1s;

                    /* position */
                    position: absolute !important;
                }
            }
        }

        .cross {
            span {
                &:nth-of-type(1) {
                    transform: translate3d(-50%, 0, 0);

                    &::before {
                        transform: translate3d(0, 0, 0);

                        /* position */
                        position: absolute !important;
                    }
                }

                &:nth-of-type(2) {
                    &::before {
                        transform: scale3d(1, 1, 1);
                        transition: transform 0.25s cubic-bezier(0, 0.27, 0.06, 1.01) 0.35s;

                        /* position */
                        position: absolute !important;
                    }
                }
            }
        }
    }
}
</style>
