<template>
    <Transition name="fade-menu-panel">
        <div v-show="isOpen" class="menu-overlay-wrapper">
            <div
                class="container"
                :class="{ 'md:max-h-[calc(100vh-170px)] overflow-y-auto': hasOverflowScrollContainer }"
            >
                <slot></slot>
            </div>
        </div>
    </Transition>
</template>

<script>
export default {
    name: 'MenuOverlayPanel',
    props: {
        isOpen: {
            type: Boolean,
            required: true,
        },
        hasOverflowScrollContainer: {
            type: Boolean,
            required: false,
            default: false,
        },
    },
};
</script>

<style scoped>
.menu-overlay-wrapper {
    @apply absolute top-0 left-0 w-full bg-black pt-32 pb-12 lg:pt-36 lg:pb-24 text-white min-h-screen md:min-h-[unset];
    @media (max-height: 350px) {
        padding-top: 70px;
    }
    button {
        @apply bg-white;
        border: 1px solid red;
    }
}
</style>
