export const state = () => ({
    isVisible: false,
    isPlaying: false,
    playingChannelName: null,
    playingProgram: null,
    popupWindow: null,
});

export const getters = {};

export const mutations = {
    setIsVisible(state, payload) {
        state.isVisible = payload;
    },
    setIsPlaying(state, payload) {
        state.isPlaying = payload;
    },
    setPlayingChannelName(state, payload) {
        state.playingChannelName = payload;
    },
    setPlayingProgram(state, payload) {
        state.playingProgram = payload;
    },
    setPopupWindow(state, payload) {
        state.popupWindow = payload;
    },
};
