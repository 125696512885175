<template>
    <span class="relative pl-4" :class="{ pulse }">{{ text }}</span>
</template>

<script>
export default {
    name: 'LiveBadge',
    props: {
        text: {
            type: String,
            required: true,
        },
        pulse: {
            type: Boolean,
            required: false,
            default: false,
        },
    },
};
</script>

<style scoped>
span::before {
    content: '';
    @apply w-2 h-2 rounded-full absolute top-1/2 -translate-y-1/2 left-0 bg-red-500;
}

span.pulse::before {
    animation: pulse 4s infinite;
}
</style>
