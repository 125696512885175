export const state = () => ({
    scrollY: 0,
    isGenericErrorMessageVisible: false,
});

export const getters = {};

export const mutations = {
    setScrollY(state, payload) {
        state.scrollY = payload;
    },
    setIsGenericErrorMessageVisible(state, payload) {
        state.isGenericErrorMessageVisible = payload;
    },
};
