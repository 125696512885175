<template>
    <div
        class="theme-dark bg-black flex relative justify-center py-32"
        :class="error.statusCode === 404 ? 'min-h-[calc(100vh-150px)]' : 'min-h-screen'"
    >
        <video
            class="w-full h-full object-cover z-0 video absolute top-0"
            muted
            loop
            autoplay
            playsinline
            :src="require('assets/videos/jazzy-smoke.mp4')"
        ></video>
        <div class="container z-10">
            <div class="flex flex-col text-white h-full items-center justify-center z-10">
                <h3
                    v-if="!isDevelopment && error.statusCode === 404"
                    class="font-FibraOne-Regular text-sm mb-4 lg:mb-3"
                >
                    404
                </h3>
                <div
                    class="font-MasifaRdCn text-4xl lg:text-5xl"
                    :class="{ 'mb-12 lg:mb-20': error.statusCode === 404 }"
                >
                    <h1 v-if="error.statusCode === 404" class="text-center">
                        A keresett oldal nem létezik, vagy megszűnt
                    </h1>
                    <h1 v-else-if="isDevelopment">{{ error.message }}</h1>
                    <h1 v-else>Oldalunk technikai okok miatt szünetel</h1>
                </div>
                <div v-if="error.statusCode === 404" class="grid grid-cols-12 md:gap-12 lg:gap-5 w-full">
                    <div class="col-span-12 lg:col-span-9">
                        <div class="border-b border-grey-700 pb-2 md:mb-8">
                            <nuxt-link to="/musorok" class="programs-button inline-flex" @click.native="closeAllPanels">
                                <span class="pointer-events-none mr-2">Jazzy műsorok</span>
                                <arrow-right-icon fill-color="#E85E4B" class="pointer-events-none icon" />
                            </nuxt-link>
                        </div>
                        <div class="w-full md:relative px-4 pb-5 pt-4 md:p-0 z-10">
                            <div
                                class="link-container link-container--programs pb-0"
                                :style="`grid-template-rows: repeat(${Math.ceil(
                                    $store.state.data.shows.length / 3
                                )}, minmax(1.5rem, 1fr))`"
                            >
                                <nuxt-link
                                    v-for="show in $store.state.data.shows"
                                    :key="`show-${show.id}`"
                                    :to="`/musorok/${show.slug}`"
                                    class="program-link"
                                >
                                    {{ show.title }}
                                </nuxt-link>
                            </div>
                        </div>
                    </div>
                    <div
                        class="col-span-12 lg:col-span-2 lg:col-start-11 flex flex-col justify-between theme-dark mt-6 lg:mt-0"
                    >
                        <div>
                            <div class="border-b border-grey-700 mb-0 lg:mb-8 pb-2">
                                <span class="programs-button inline-flex">Jazzy Rádió</span>
                            </div>
                            <div class="link-container grid grid-cols-1 gap-y-3 px-4 pt-0">
                                <nuxt-link to="/musorvezetok" class="program-link" @click.native="closeAllPanels">
                                    Műsorvezetőink
                                </nuxt-link>
                                <nuxt-link to="/kapcsolat" class="program-link" @click.native="closeAllPanels">
                                    Kapcsolat
                                </nuxt-link>
                                <nuxt-link
                                    v-for="siteContent in $store.state.data.siteContents.filter(
                                        (x) => x.show_in_header
                                    )"
                                    :key="`site-content-menu-item-${siteContent.id}`"
                                    :to="`/${siteContent.slug}`"
                                    class="program-link"
                                >
                                    {{ siteContent.title }}
                                </nuxt-link>
                                <nuxt-link
                                    v-for="article in $store.state.data.articles.filter((x) => x.show_in_header)"
                                    :key="`article-menu-item-${article.id}`"
                                    :to="`/${article.slug}`"
                                    class="program-link"
                                >
                                    {{ article.short_title }}
                                </nuxt-link>
                            </div>
                        </div>
                    </div>
                </div>
                <nuxt-link v-if="error.statusCode === 404" class="p-button mt-9 lg:mt-12" to="/">
                    Vissza a nyitóoldalra
                </nuxt-link>
            </div>
        </div>

        <img
            class="absolute top-20 w-[40%] sm:w-[30%] lg:w-auto :top-36 left-0"
            :src="require('assets/images/decorators/zigzag_lg.png')"
            alt=""
        />
        <img
            class="absolute w-10 right-12 top-16 lg:right-40 lg:top-36 lg:w-auto"
            :src="require('assets/images/decorators/note_1.svg')"
            alt=""
        />
        <img
            class="absolute right-32 top-40 lg:right-[34%] lg:top-96 w-5 lg:w-auto hidden lg:block"
            :src="require('assets/images/decorators/note_2.svg')"
            alt=""
        />
        <img
            class="absolute bottom-20 lg:top-1/2 left-10 lg:left-64"
            :src="require('assets/images/decorators/note_3.svg')"
            alt=""
        />
    </div>
</template>

<script>
export default {
    name: 'Error',
    layout: 'Default',
    props: {
        error: {
            type: Object,
            required: true,
        },
    },
    computed: {
        isDevelopment() {
            return process.env.NODE_ENV === 'development';
        },
    },
    watchQuery: ['page'],
};
</script>

<style scoped>
.video {
    @apply opacity-0;
    @keyframes fadeIn {
        from {
            opacity: 0;
        }
        to {
            opacity: 0.3;
        }
    }
    animation: fadeIn 1.5s forwards 1s;
}
.program-link {
    @apply whitespace-nowrap overflow-hidden overflow-ellipsis opacity-80 transition hover:opacity-100 transition-opacity col-span-3 md:col-span-1;
}

.link-container {
    @apply pt-4 md:pt-0;
    &--programs {
        @apply grid grid-cols-3 grid-rows-6 gap-x-5 gap-y-3  grid-flow-row md:grid-flow-col-dense;
        @media (max-width: 639px) {
            grid-template-rows: unset !important;
        }
    }
}
</style>
