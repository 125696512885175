<template>
    <div>
        <Nuxt class="relative" />
        <MainAudioPlayer class="z-20" />
    </div>
</template>

<script>
import MainAudioPlayer from '../components/Players/MainAudioPlayer/MainAudioPlayer';

export default {
    name: 'Player',
    components: {
        MainAudioPlayer,
    },
    middleware: ['redirects', 'init'],
};
</script>

<style scoped></style>
