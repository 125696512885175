export const state = () => ({
    windowWidth: 0,
    isWebView: false,
    areCSSAnimationsAllowed: true,
    host: null,
});

export const mutations = {
    setHost(state, payload) {
        state.host = payload;
    },
    setWindowWidth(state, payload) {
        state.windowWidth = payload;
    },
    setIsWebView(state, payload) {
        state.isWebView = payload;
    },
    setAreCSSAnimationsAllowed(state, payload) {
        state.areCSSAnimationsAllowed = payload;
    },
};

export const actions = {
    async setFallbackDataForCurrentJazzyLiveShow({ commit }) {
        // if current jazzy live show is missing for some reason in the /api/ response,
        // assemble a fallback one from data coming from azuracast stream

        return await this.$axios.$get('https://radio.musorok.org/api/nowplaying/jazzy').then((res) => {
            commit('channel/setCurrentJazzyLiveShow', {
                program: null,
                isFallback: true,
                schedule: {
                    date: this.$dayjs().format('YYYY-MM-DD'),
                    start: this.$dayjs().format('HH:mm'),
                    end: this.$dayjs().format('HH:mm'),
                },
                show: {
                    color: '#E85E4B',
                    cover_image: require('~/assets/images/placeholders/jazzy-thumbnail-fallback_transparent.png'),
                    slug: '',
                    subtitle: res.now_playing.song.artist,
                    title: res.now_playing.song.title,
                },
            });
        });
    },
    async updateLiveShowAndWeeklyScheduleData({ state, commit, dispatch }, shID) {
        return await this.$axios.$get('/').then(async (res) => {
            if (res.success === 1) {
                commit('data/setScheduleByDays', res.data.schedule);
                if (res.data.current) {
                    commit('channel/setCurrentJazzyLiveShow', res.data.current);
                }
                if (shID) {
                    commit('channel/setCurrentJazzyLiveShowShID', shID);
                }
            }

            if (!state.channel.currentJazzyLiveShow) {
                await dispatch('setFallbackDataForCurrentJazzyLiveShow');
            }
        });
    },

    nuxtServerInit({ commit }, { req }) {
        commit('setHost', req.headers.host);
    },
};
