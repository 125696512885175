<template>
    <div class="relative">
        <div ref="scrollTarget" class="absolute -top-20 pointer-events-none"></div>
        <form
            class="relative flex items-center flex-nowrap overflow-hidden border-b border-grey-400 mb-4"
            @submit.prevent="handleSubmit"
        >
            <input
                ref="input"
                v-model="query"
                name="main-search"
                class="py-4 w-full focus:outline-none bg-transparent z-10 lg:max-w-[calc(100%-200px)] text-3xl lg:text-4xl"
                :placeholder="placeholder"
                type="text"
                autocomplete="off"
                @input="throttledInputHandler"
            />
            <div class="absolute top-1/2 right-0 -translate-y-1/2 pr-4 lg:pr-4 transition-opacity z-15 flex theme-dark">
                <button
                    type="submit"
                    class="w-5 h-5 mr-6 bg-contain bg-center bg-no-repeat opacity-40 hover:opacity-100 transition"
                    :disabled="query.length <= 2"
                >
                    <MagnifyingIcon :color="light ? 'white' : '#121316'" />
                </button>
                <button
                    type="button"
                    class="w-5 h-5 bg-contain bg-center bg-no-repeat opacity-40 hover:opacity-100 transition"
                    @click="handleReset"
                >
                    <CloseIcon :color="light ? 'white' : '#121316'" />
                </button>
            </div>
        </form>
        <span class="text-xs">Írd be a keresett kifejezést. Minimum 3 karakter.</span>
        <div v-show="isSearchDropdownOpen" class="search-dropdown-container xl:-mt-10 bg-black px-6 py-4 nicescroll">
            <div v-if="processedResultCategories.length">
                <div v-for="(category, i) in processedResultCategories" :key="`search-result-category-${i}`">
                    <div v-if="category.results.length > 0">
                        <div
                            class="font-FibraOne-UltraBold text-grey-200 py-1 mb-2 text-white"
                            :class="{ 'mt-4': i > 0 }"
                        >
                            {{ category.label }}
                        </div>
                        {{/* pid for "problem id" */}}
                        <nuxt-link
                            v-for="(result, j) in category.results"
                            :key="`search-result-${j}`"
                            :to="result.url"
                            class="block py-1 transition-colors text-grey-300 hover:text-white"
                            @click.native="handleResultClick"
                            v-html="result.template"
                        ></nuxt-link>
                    </div>
                    <div v-else class="text-grey-325">Nincsenek találatok a megadott kifejezéssel</div>
                </div>
            </div>
            <div v-else class="text-grey-325">Nincsenek találatok a megadott kifejezéssel</div>
        </div>
    </div>
</template>

<script>
import { throttle, debounce } from 'throttle-debounce';
import MagnifyingIcon from '~/components/UI/Icons/MagnifyingIcon';
import CloseIcon from '~/components/UI/Icons/CloseIcon';

/*
 *
 * types are:
 * - standard:   e.g.
 * - slim:       e.g.
 *
 * */

export default {
    name: 'SearchBlock',
    components: {
        MagnifyingIcon,
        CloseIcon,
    },
    props: {
        type: {
            type: String,
            required: false,
            default: 'standard',
        },
        placeholder: {
            type: String,
            required: false,
            default: 'Keresés',
        },
        submitCb: {
            type: Function,
            required: false,
            default: () => {},
        },
        cancelCb: {
            type: Function,
            required: false,
            default: () => {},
        },
        shouldRedirectAfterSubmit: {
            type: Boolean,
            required: false,
            default: false,
        },
        focusOnMount: {
            type: Boolean,
            required: false,
            default: false,
        },
        borderless: {
            type: Boolean,
            required: false,
            default: false,
        },
        defaultQuery: {
            type: String,
            required: false,
            default: '',
        },
        light: {
            type: Boolean,
            required: false,
            default: false,
        },
    },
    data() {
        return {
            query: this.defaultQuery,
            isSearchDropdownOpen: false,
            processedResultCategories: [],
            throttledInputHandler: throttle(300, this.handleInput),
            debouncedResizeHandler: debounce(10, this.scrollInputToTop),
        };
    },
    mounted() {
        document.addEventListener('click', this.handleClickOutside);
        if (this.focusOnMount) {
            this.$refs.input.focus();
        }
        if (this.$device.isMobileOrTablet) {
            window.addEventListener('resize', this.debouncedResizeHandler);
        }
    },
    destroyed() {
        document.removeEventListener('click', this.handleClickOutside);
        if (this.$device.isMobileOrTablet) {
            window.removeEventListener('resize', this.debouncedResizeHandler);
        }
    },
    methods: {
        transformQuery(query, string) {
            const regex = new RegExp(query, 'gi');

            // find all the matched substrings in the question's name
            const matches = string.match(regex);

            if (!matches) {
                return string;
            }

            // wrap matched substrings in <b></b> one by one
            matches.forEach((match) => {
                const _regex = new RegExp(`${match}(?!</b>)`);
                string = string.replace(_regex, `<b>$&</b>`);
            });

            return string;
        },
        handleInput() {
            if (this.query.trim().length <= 2) {
                this.isSearchDropdownOpen = false;
                return;
            }

            const _resultCategories = [];

            this.$axios.$get(`/search?q=${this.query.trim()}`).then((res) => {
                if (res.success === 1) {
                    Object.keys(res.results).forEach((categoryKey) => {
                        if (!res.results[categoryKey].length) return;
                        _resultCategories.push({
                            key: categoryKey,
                            label: this.$store.state.data.searchLabels[categoryKey],
                            results: res.results[categoryKey],
                        });
                    });

                    this.processedResultCategories = _resultCategories.map((category) => {
                        category.results = category.results.map((result) => {
                            const processedResult = {};

                            processedResult.id = result.id;

                            if (category.key === 'shows') {
                                processedResult.template = this.transformQuery(this.query, result.title);
                                processedResult.url = `/musorok/${result.slug}`;
                            } else if (category.key === 'hosts') {
                                processedResult.template = this.transformQuery(this.query, result.name);
                                processedResult.url = `/musorvezetok/${result.slug}`;
                            } else if (category.key === 'articles') {
                                processedResult.template = this.transformQuery(this.query, result.title);
                                processedResult.url = `/${result.slug}`;
                            } else if (category.key === 'programs') {
                                processedResult.template = this.transformQuery(this.query, result.title);
                                processedResult.url = `/adasok/${result.slug}`;
                            }

                            return processedResult;
                        });

                        return category;
                    });
                }
                // we need to do a second check just in case the form has been
                // submitted like .1s before the api response arrived
                if (this.query.trim()) {
                    this.isSearchDropdownOpen = true;
                }
            });
        },
        handleClickOutside(event) {
            if (!this.$el.contains(event.target)) {
                this.isSearchDropdownOpen = false;
                this.processedResultCategories = [];
            }
        },
        scrollInputToTop() {
            // this method positions the input field to the top of the screen on mobile devices when focused,
            // in order to not let the soft keyboard cover up the input field and the results dropdown
            if (this.$refs.input === document.activeElement) {
                this.$refs.scrollTarget.scrollIntoView({
                    behavior: 'smooth',
                });
            }
        },
        handleResultClick() {
            this.closeDropdown();
            this.submitCb();
        },
        handleSubmit() {
            const _query = this.query;
            this.$refs.input.blur();
            this.closeDropdown();
            this.submitCb(_query);
            if (this.shouldRedirectAfterSubmit) return;
            this.$router.push('/kereses?q=' + encodeURIComponent(_query));
        },
        handleReset() {
            this.query = '';
        },
        closeDropdown() {
            this.isSearchDropdownOpen = false;
            this.processedResultCategories = [];
            this.query = '';
            this.$emit('close');
        },
    },
};
</script>

<style scoped>
input {
    &::placeholder {
        @apply text-grey-400;
    }
}

.search-dropdown-container {
    @apply absolute w-full transition z-20 py-4 flex flex-col gap-2 max-h-[calc(100vh-260px)] sm:max-h-72 overflow-y-auto;
    height: calc(100vh - 150px);
    @media (min-height: 350px) {
        height: calc(100vh - 230px);
    }
}
</style>
