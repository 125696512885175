export const state = () => ({
    isCookieBannerVisible: false,
});

export const mutations = {
    setCookieBannerVisibility(state, payload) {
        state.isCookieBannerVisible = payload;
    },
};

export const actions = {
    openBanner({ commit }) {
        commit('setCookieBannerVisibility', true);
    },
    closeBanner({ commit }) {
        commit('setCookieBannerVisibility', false);
    },
};
