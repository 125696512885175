<template>
    <svg
        class="w-full h-full"
        width="66"
        height="66"
        viewBox="0 0 66 66"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M53 30C53 42.7025 42.7025 53 30 53C17.2975 53 7 42.7025 7 30C7 17.2975 17.2975 7 30 7C42.7025 7 53 17.2975 53 30ZM48.5663 53.5663C43.4589 57.5955 37.0103 60 30 60C13.4315 60 0 46.5685 0 30C0 13.4315 13.4315 0 30 0C46.5685 0 60 13.4315 60 30C60 37.036 57.5778 43.5062 53.5219 48.6224L65.9497 61.0503L61 66L48.5663 53.5663Z"
            :fill="color"
        />
    </svg>
</template>

<script>
export default {
    name: 'MagnifyIcon',
    props: {
        color: {
            type: String,
            required: false,
            default: '#121316',
        },
    },
};
</script>
