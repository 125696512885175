var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"relative group flex items-center justify-center",class:{
        'h-12 min-h-12 w-12 min-w-12': _vm.mobileSize === 'xs' || _vm.desktopSize === 'xs',
        'h-16 min-h-16 w-16 min-w-16': _vm.mobileSize === 'sm' || _vm.desktopSize === 'sm',
        'h-36 min-h-36 w-36 min-w-36': _vm.mobileSize === 'lg',
        'h-45 min-h-45 w-45 min-w-45': _vm.mobileSize === 'xl',
        'sm:h-24 sm:min-h-24 sm:w-24 sm:min-w-24': _vm.desktopSize === 'md',
        'sm:h-45 sm:min-h-45 sm:w-45 sm:min-w-45': _vm.desktopSize === 'xl',
    }},[(_vm.color)?_c('div',{staticClass:"color-circle absolute w-[80%] h-[80%] bg-red-500 z-0 transition",class:{ 'rounded-full': !_vm.isRectangle },style:(`background-color: ${_vm.color}`)}):_vm._e(),_vm._v(" "),_c('img',{staticClass:"absolute w-full h-full object-contain object-center z-10",class:{ 'rounded-full': !_vm.isRectangle },attrs:{"src":_vm.image ? _vm.image : require('~/assets/images/placeholders/jazzy-thumbnail-fallback_transparent.png'),"alt":_vm.alt}}),_vm._v("\n    "+_vm._s(/* Should you need any classes not to be purged, apply them on this div */)+"\n    "),_c('div',{staticClass:"invisible pointer-events-none absolute min-w-16 sm:min-w-24"})])
}
var staticRenderFns = []

export { render, staticRenderFns }