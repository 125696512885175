<template>
    <div
        id="__layout__content"
        class="flex flex-col min-h-screen"
        :class="{ 'transitions-enabled': $store.state.areCSSAnimationsAllowed }"
    >
        <Header v-if="!$store.state.isWebView" class="z-40" />

        <main class="flex-grow" :class="{ 'pt-18 sm:pt-14': !$store.state.isWebView }">
            <Nuxt class="relative" />
        </main>

        <Footer v-if="!$store.state.isWebView" class="relative lg:z-5" />

        <MainAudioPlayer class="z-20" />

        <GridOverlay />

        <CookieBanner class="z-50" />
    </div>
</template>

<script>
import Header from '../components/Layout/Header';
import Footer from '../components/Layout/Footer';
import MainAudioPlayer from '../components/Players/MainAudioPlayer/MainAudioPlayer';
import GridOverlay from '../components/Layout/GridOverlay';
import CookieBanner from '../components/Layout/CookieBanner';

export default {
    name: 'Default',
    components: {
        Header,
        Footer,
        MainAudioPlayer,
        GridOverlay,
        CookieBanner,
    },
    middleware: ['redirects', 'init'],
};
</script>
