export default ({ app, $config, route, req }) => {
    const script = [];
    const meta = [];

    let host;

    if (process.server) {
        host = req.headers['x-forwarded-host'] || req.headers.host;
    } else {
        host = window.location.origin;
    }

    script.push({
        hid: 'og:image',
        property: 'og:image',
        content: `${host}/og-image_2.png`,
    });

    script.push({
        hid: 'og:image:url',
        property: 'og:image:url',
        content: `${host}/og-image_2.png`,
    });

    meta.push({
        property: 'og:url',
        content: host + route.fullPath,
    });

    meta.push({
        name: 'robots',
        content: $config.nuxtENV === 'production' && host && !/melkweg|mlkwg/.test(host) ? 'all' : 'noindex, nofollow',
    });

    app.head.script.push(...script);
    app.head.meta.push(...meta);
};
