import Vue from 'vue';
import VueSanitize from 'vue-sanitize';

const defaultOptions = {
    allowedTags: ['div', 'p', 'span', 'b', 'strong'],
    allowedAttributes: {
        a: ['href', 'target'],
    },
};
Vue.use(VueSanitize, defaultOptions);
