<template>
    <div
        class="relative group flex items-center justify-center"
        :class="{
            'h-12 min-h-12 w-12 min-w-12': mobileSize === 'xs' || desktopSize === 'xs',
            'h-16 min-h-16 w-16 min-w-16': mobileSize === 'sm' || desktopSize === 'sm',
            'h-36 min-h-36 w-36 min-w-36': mobileSize === 'lg',
            'h-45 min-h-45 w-45 min-w-45': mobileSize === 'xl',
            'sm:h-24 sm:min-h-24 sm:w-24 sm:min-w-24': desktopSize === 'md',
            'sm:h-45 sm:min-h-45 sm:w-45 sm:min-w-45': desktopSize === 'xl',
        }"
    >
        <div
            v-if="color"
            class="color-circle absolute w-[80%] h-[80%] bg-red-500 z-0 transition"
            :class="{ 'rounded-full': !isRectangle }"
            :style="`background-color: ${color}`"
        ></div>
        <img
            class="absolute w-full h-full object-contain object-center z-10"
            :class="{ 'rounded-full': !isRectangle }"
            :src="image ? image : require('~/assets/images/placeholders/jazzy-thumbnail-fallback_transparent.png')"
            :alt="alt"
        />
        {{/* Should you need any classes not to be purged, apply them on this div */}}
        <div class="invisible pointer-events-none absolute min-w-16 sm:min-w-24"></div>
    </div>
</template>

<script>
export default {
    name: 'ImageBadge',
    props: {
        mobileSize: {
            type: String,
            required: true,
            validator(value) {
                return ['xs', 'sm', 'lg', 'xl'].includes(value);
            },
        },
        desktopSize: {
            type: String,
            required: true,
            validator(value) {
                return ['xs', 'sm', 'md', 'lg', 'xl'].includes(value);
            },
        },
        alt: {
            type: String,
            required: false,
            default: 'Jazzy',
        },
        color: {
            type: String,
            required: false,
            default: null,
        },
        image: {
            type: [String, Boolean],
            required: false,
            default: null,
        },
        channel: {
            type: String,
            required: false,
            default: null,
        },
        isRectangle: {
            type: Boolean,
            required: false,
            default: false,
        },
    },
};
</script>

<style>
a:hover {
    .color-circle {
        transform: scale(110%);
    }
}
</style>
