<template>
    <header class="fixed top-0 left-0 w-full">
        <div
            class="flex justify-between items-center relative z-20 bg-black pb-4 sm:pb-5 pt-10 sm:py-5 px-3 sm:px-10 min-h-[76px] sm:min-h-[60px]"
        >
            <div class="inline-flex items-center">
                <AnimatingHamburgerButton :active="isMenuOverlayOpen" @click.native="toggleMenuOverlay" />
                <nuxt-link
                    to="/"
                    class="fixed top-11 left-1/2 -translate-x-1/2 sm:top-0 sm:left-0 sm:translate-x-[none] sm:relative sm:ml-4"
                    @click.native="closeAllPanels"
                >
                    <object
                        class="h-4 sm:h-5"
                        type="image/svg+xml"
                        :data="require('~/assets/images/logos/brand_logo.svg')"
                    ></object>
                </nuxt-link>
            </div>
            <div class="hidden sm:inline-flex items-center absolute top-5 left-1/2 -translate-x-1/2 text-sm text-white">
                <LiveBadge text="Élő:" />
                <span class="ml-2">{{ $store.state.channel.currentJazzyLiveShow.show.title }} </span>
                <Transition name="fade-opacity">
                    <p-button
                        v-if="$route.name !== 'index'"
                        icon-only
                        class="p-button-xxs absolute -right-8 top-1/2 -translate-y-1/2"
                        @click.native="handlePlayButtonClick"
                    >
                        <template #icon-left>
                            <pause-icon
                                v-if="
                                    $store.state.player.isPlaying &&
                                    $store.state.player.playingChannelName === 'jazzylive'
                                "
                                :size="18"
                            />
                            <play-icon v-else :size="18" />
                        </template>
                    </p-button>
                </Transition>
            </div>
            <AnimatingSearchButton :active="isSearchPanelOpen" @click.native="toggleSearchPanel" />
        </div>

        {{/* * */}}
        {{/* * */}}
        {{/* * */}}
        {{/* Shows menu panel */}}
        {{/* * */}}
        <MenuOverlayPanel class="z-10" :is-open="isMenuOverlayOpen" has-overflow-scroll-container>
            <div class="grid grid-cols-12 md:gap-12 lg:gap-5">
                <div class="col-span-12 lg:col-span-9">
                    {{/* Panel header */}}
                    <div class="border-b border-grey-700 md:mb-8">
                        <nuxt-link
                            to="/musorok"
                            class="programs-button hidden md:inline-flex"
                            @click.native="closeAllPanels"
                        >
                            <span class="pointer-events-none mr-2">Jazzy műsorok</span>
                            <arrow-right-icon fill-color="#E85E4B" class="pointer-events-none icon" />
                        </nuxt-link>
                        <button
                            class="programs-button inline-flex md:hidden"
                            @click="isMobileProgramListPanelOpen = true"
                        >
                            <span class="mr-2">Jazzy műsorok</span>
                            <arrow-right-icon fill-color="#E85E4B" class="icon" />
                        </button>
                    </div>
                    <div
                        class="fixed top-32 left-0 w-full md:relative px-4 pb-5 pt-4 md:p-0 bg-black z-10 transition-transform md:-translate-x-0 min-h-screen md:min-h-[unset] md:top-[unset] md:left-[unset]"
                        :class="{ '-translate-x-full': !isMobileProgramListPanelOpen }"
                    >
                        <div class="border-b border-grey-700 md:mb-8 md:hidden">
                            <button
                                class="inline-flex items-center justify-center px-3 pb-3 pt-1 -ml-2"
                                @click="isMobileProgramListPanelOpen = false"
                            >
                                <arrow-left-icon />
                            </button>
                        </div>

                        {{/* The 3 columns of list items */}}
                        <div class="link-container pb-16 lg:pb-0 grid grid-cols-1 sm:grid-cols-3 gap-5">
                            <div class="flex flex-col gap-3">
                                <nuxt-link
                                    v-for="show in $store.state.data.shows.slice(
                                        0,
                                        Math.ceil($store.state.data.shows.length / 3)
                                    )"
                                    :key="`show-${show.id}`"
                                    :to="`/musorok/${show.slug}`"
                                    class="program-link"
                                    active-class="program-link--active"
                                >
                                    <MenuShowListItemInner :slug="show.slug" :title="show.title" />
                                </nuxt-link>
                            </div>

                            <div class="flex flex-col gap-3">
                                <nuxt-link
                                    v-for="show in $store.state.data.shows.slice(
                                        Math.ceil($store.state.data.shows.length / 3),
                                        Math.ceil($store.state.data.shows.length / 3) * 2
                                    )"
                                    :key="`show-${show.id}`"
                                    :to="`/musorok/${show.slug}`"
                                    class="program-link"
                                    active-class="program-link--active"
                                >
                                    <MenuShowListItemInner :slug="show.slug" :title="show.title" />
                                </nuxt-link>
                            </div>

                            <div class="flex flex-col gap-3">
                                <nuxt-link
                                    v-for="show in $store.state.data.shows.slice(
                                        Math.ceil($store.state.data.shows.length / 3) * 2
                                    )"
                                    :key="`show-${show.id}`"
                                    :to="`/musorok/${show.slug}`"
                                    class="program-link"
                                    active-class="program-link--active"
                                >
                                    <MenuShowListItemInner :slug="show.slug" :title="show.title" />
                                </nuxt-link>
                            </div>
                        </div>
                    </div>
                </div>

                {{/* Other site links on the right side */}}
                <div class="col-span-12 lg:col-span-2 lg:col-start-11 flex flex-col justify-between theme-dark">
                    <div>
                        <div class="hidden md:block border-b border-grey-700 mb-8">
                            <span class="programs-button inline-flex">Jazzy Rádió</span>
                        </div>
                        <div class="link-container flex flex-col gap-y-3">
                            <nuxt-link
                                to="/musorvezetok"
                                class="program-link"
                                active-class="program-link--active"
                                @click.native="closeAllPanels"
                            >
                                Műsorvezetőink
                            </nuxt-link>
                            <nuxt-link
                                v-for="siteContent in $store.state.data.siteContents.filter((x) => x.show_in_header)"
                                :key="`site-content-menu-item-${siteContent.id}`"
                                :to="`/${siteContent.slug}`"
                                class="program-link"
                                active-class="program-link--active"
                                @click.native="closeAllPanels"
                            >
                                {{ siteContent.title }}
                            </nuxt-link>
                            <nuxt-link
                                v-for="article in $store.state.data.articles.filter((x) => x.show_in_header)"
                                :key="`article-menu-item-${article.id}`"
                                :to="`/${article.slug}`"
                                class="program-link"
                                active-class="program-link--active"
                                @click.native="closeAllPanels"
                            >
                                {{ article.short_title }}
                            </nuxt-link>
                        </div>
                    </div>
                    <div class="h-20 lg:h-16 relative">
                        <p-button
                            to="/playlist"
                            class="absolute left-0 lg:left-[unset] lg:right-0 bottom-0 p-button"
                            label="Korábban szólt számok"
                            @click.native="closeAllPanels"
                        >
                            <template #icon-left>
                                <update-icon :size="18" />
                            </template>
                        </p-button>
                    </div>
                </div>
            </div>
        </MenuOverlayPanel>

        {{/* * */}}
        {{/* * */}}
        {{/* * */}}
        {{/* Search panel */}}
        {{/* * */}}
        <MenuOverlayPanel class="z-10" :is-open="isSearchPanelOpen">
            <div class="search-block-wrapper md:max-h-[calc(100vh-200px)]">
                <SearchBlock ref="searchBlock" light type="standard" @close="closeAllPanels" />
            </div>
        </MenuOverlayPanel>

        <portal selector="body">
            <div
                class="fixed top-0 left-0 w-screen h-screen bg-grey-700/50 z-10 transition-opacity delay-100"
                :class="{ 'opacity-0 pointer-events-none': !isMenuOverlayOpen && !isSearchPanelOpen }"
                @click="closeAllPanels"
            ></div>
        </portal>
    </header>
</template>

<script>
import AnimatingHamburgerButton from '../UI/Buttons/AnimatingHamburgerButton';
import AnimatingSearchButton from '../UI/Buttons/AnimatingSearchButton';
import MenuShowListItemInner from '../UI/MenuShowListItemInner';
import MenuOverlayPanel from '../Modals/MenuOverlayPanel/MenuOverlayPanel';
import SearchBlock from '../Sections/SectionSearch/SearchBlock';

export default {
    name: 'Header',
    components: {
        AnimatingHamburgerButton,
        AnimatingSearchButton,
        MenuOverlayPanel,
        MenuShowListItemInner,
        SearchBlock,
    },
    data() {
        return {
            isMenuOverlayOpen: false,
            isSearchPanelOpen: false,
            arePanelsToggleable: true,
            isMobileProgramListPanelOpen: false,
        };
    },
    watch: {
        '$route.path'() {
            this.closeAllPanels();
        },
        isMenuOverlayOpen(value) {
            this.handleBodyScrollDisabledState(value);
        },
        isSearchPanelOpen(value) {
            this.handleBodyScrollDisabledState(value);
        },
    },
    mounted() {
        window.addEventListener('keyup', (event) => {
            if (event.key === 'Escape') {
                this.isMenuOverlayOpen = false;
                this.isSearchPanelOpen = false;
                this.isMobileProgramListPanelOpen = false;
            }
        });
    },
    methods: {
        handleBodyScrollDisabledState(value) {
            if (value) {
                document.body.classList.add('overflow-hidden');
            } else if (!this.isMenuOverlayOpen && !this.isSearchPanelOpen) {
                document.body.classList.remove('overflow-hidden');
            }
        },
        toggle(propName) {
            this[propName] = !this[propName];
        },
        cooDown() {
            this.arePanelsToggleable = false;
            setTimeout(() => {
                this.arePanelsToggleable = true;
            }, 500);
        },
        toggleMenuOverlay() {
            if (!this.arePanelsToggleable) {
                return;
            }
            this.isMenuOverlayOpen = !this.isMenuOverlayOpen;
            this.isSearchPanelOpen = false;
            this.cooDown();
        },
        toggleSearchPanel() {
            if (!this.arePanelsToggleable) {
                return;
            }
            this.isSearchPanelOpen = !this.isSearchPanelOpen;
            this.isMenuOverlayOpen = false;
            this.isMobileProgramListPanelOpen = false;
            this.$nextTick(() => {
                if (this.isSearchPanelOpen) {
                    this.$refs.searchBlock.$refs.input.focus();
                }
            });
            this.cooDown();
        },
        closeAllPanels() {
            this.isMenuOverlayOpen = false;
            this.isSearchPanelOpen = false;
            this.isMobileProgramListPanelOpen = false;
        },
        handlePlayButtonClick() {
            // If we are on the channel's tab that is currently being played too, we pause the player
            if (this.$store.state.player.isPlaying && this.$store.state.player.playingChannelName === 'jazzylive') {
                this.$nuxt.$emit('pauseMainPlayer');
                return;
            }

            // Else we update the player with the current channel's data (the channel we see the tab of in the hero).
            // This will result in redundant updates too, but it doesn't do any harm, so it is what it is
            const payload = {};

            payload.src = this.$store.state.channel.jazzy.station.listen_url;
            payload.color = this.$store.state.channel.currentJazzyLiveShow.color;
            payload.artist = 'Élőben most';

            payload.title = this.title;
            payload.image = this.image;

            this.$store.commit('player/setPlayingChannelName', 'jazzylive');
            this.$store.commit('player/setPlayingProgram', null);
            this.$nuxt.$emit('updateMainPlayerStreamUrl', payload.src);
            this.$nuxt.$emit('playMainPlayer');
        },
    },
};
</script>

<style scoped>
.search-button {
    @apply inline-flex items-center justify-center opacity-80 rounded-full p-3 transition-opacity col-span-3 lg:col-span-1;
}

.program-link {
    @apply text-white/80 transition-colors col-span-3 md:col-span-1;
}

.program-link.program-link--active {
    @apply font-FibraOne-UltraBold text-white;
}

.link-container {
    @apply pt-4 md:pt-0 max-h-[calc(100vh-250px)] md:max-h-[unset] lg:max-h-[calc(100vh-250px)] overflow-y-auto;
}

.programs-button {
    @apply font-FibraOne-UltraBold py-4 md:pb-2 md:pt-0  items-center;
    .icon {
        @apply transition-transform;
    }
}

@media (hover: hover) {
    .search-button:hover,
    .program-link:hover {
        @apply text-white;
    }
    .programs-button:hover .icon {
        transform: translateX(3px);
    }
}

.search-block-wrapper {
    min-height: calc(100vh - 100px);
    @media (min-height: 350px) {
        min-height: calc(100vh - 200px);
    }
    @media (min-height: 600px) {
        min-height: 400px;
    }
}
</style>
