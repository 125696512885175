export default function ({ $axios, $config }) {
    // $axios.interceptors.request.use((request) => {
    //     return request;
    // });

    // $axios.interceptors.response.use((response) => {
    //     return response;
    // });

    $axios.onError((err) => {
        if ($config.nuxtENV === 'production') {
            return;
        }

        console.log('response error on ' + err.config.url);
        // in case of response timeout we don't have a response
        if (err.response) {
            console.log(err.response.status + ' (' + err.response.statusText + ')');
        } else {
            console.log(err.message);
        }
    });

    $axios.defaults.baseURL = $config.baseURL + '/api';
    // $axios.defaults.timeout = 3000;
    $axios.defaults.retry = { retries: 1 };
}
