export const state = () => ({
    isGridOverlayVisible: false,
    scrollY: 0,
});

export const mutations = {
    setIsGridOverlayVisible(state, payload) {
        state.isGridOverlayVisible = payload;
    },
    setScrollY(state, payload) {
        state.scrollY = payload;
    },
};
