<template>
    <svg
        class="w-full h-full"
        width="52"
        height="52"
        viewBox="0 0 52 52"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M25.8345 30.8345L46.669 51.669L51.6188 46.7193L30.7843 25.8848L51.669 5L46.7193 0.0502526L25.8345 20.935L4.94975 0.0502319L0 4.99998L20.8848 25.8848L0.0502526 46.7193L5 51.669L25.8345 30.8345Z"
            :fill="color"
        />
    </svg>
</template>

<script>
export default {
    name: 'MagnifyIcon',
    props: {
        color: {
            type: String,
            required: false,
            default: '#121316',
        },
    },
};
</script>
