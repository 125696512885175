<template>
    <a v-if="to && external" class="p-button p-component" :class="{ 'p-button-icon-only': iconOnly }" :href="to">
        <slot name="icon-left"></slot>
        <span v-if="label && !iconOnly" class="p-button-label">{{ label }}</span>
        <slot name="icon-right"></slot>
    </a>
    <NuxtLink v-else-if="to" class="p-button p-component" :class="{ 'p-button-icon-only': iconOnly }" :to="to">
        <slot name="icon-left"></slot>
        <span v-if="label && !iconOnly" class="p-button-label">{{ label }}</span>
        <slot name="icon-right"></slot>
    </NuxtLink>
    <button v-else class="p-button p-component" :class="{ 'p-button-icon-only': iconOnly }">
        <slot name="icon-left"></slot>
        <span v-if="label && !iconOnly" class="p-button-label">{{ label }}</span>
        <slot name="icon-right"></slot>
    </button>
</template>

<script>
export default {
    name: 'ButtonLink',
    props: {
        to: {
            type: String,
            required: false,
            default: null,
        },
        external: {
            type: Boolean,
            required: false,
            default: false,
        },
        label: {
            type: String,
            required: false,
            default: null,
        },
        iconOnly: {
            type: Boolean,
            required: false,
            default: false,
        },
    },
};
</script>

<style scoped></style>
