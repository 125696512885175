import genericUrls from './urls.json';

export default function ({ redirect, route }) {
    if (process.server) {
        const redirectUrl = genericUrls.data.find((data) => data.old === route.path);
        if (redirectUrl) {
            return redirect(redirectUrl.new);
        }
    }
}
