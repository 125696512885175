import WebSocket from 'isomorphic-ws';

export default ({ store, $dayjs, error }) => {
    const ws = new WebSocket('wss://radio.musorok.org/api/live/nowplaying/websocket');

    ws.onopen = () => {
        ws.send(
            JSON.stringify({
                subs: {
                    'station:jazzy': {},
                    'station:jazzycool': {},
                    'station:jazzygroove': {},
                    'station:jazzysoul': {},
                },
            })
        );
    };

    ws.onmessage = (data) => {
        const { channel, pub } = JSON.parse(data.data);

        if (!channel || !pub) {
            return;
        }

        const streamData = pub.data.np;
        const stationName = streamData.station.shortcode;

        // The socket will not only message the client when the currently
        // played track/show changes, but basically anytime (dunno the reason).
        // If the channel that was updated was the "jazzy" one, we wanna concurrently update our show list as well. (request to "/api/")
        // But to avoid making a request every time the socket sends a message, we make sure that the currently playing song's id
        // is different from the one we have stored in vuex
        if (
            stationName === 'jazzy' &&
            store.state.channel.jazzy &&
            streamData.now_playing.sh_id !== store.state.channel.currentJazzyLiveShowShID &&
            $dayjs().isSameOrAfter(
                $dayjs(
                    `${store.state.channel.currentJazzyLiveShow.schedule.date} ${store.state.channel.currentJazzyLiveShow.schedule.end}:01`
                ),
                'second'
            )
        ) {
            store.dispatch('updateLiveShowAndWeeklyScheduleData', streamData.now_playing.sh_id).catch((err) => {
                console.log(err);
                return error({ message: 'Sajnáljuk, szerverhiba történt' });
            });
        }

        store.commit('channel/setChannelData', { channel: stationName, data: streamData });
    };

    ws.onerror = (event) => {
        console.log('wss error');
        console.log(event);
        error({ message: 'Sajnáljuk, szerverhiba történt' });
    };
};
