export const state = () => ({
    activeChannelTab: 'jazzylive',
    currentJazzyLiveShowShID: null,
    currentJazzyLiveShow: null,
    jazzy: null,
    jazzycool: null,
    jazzygroove: null,
    jazzysoul: null,
});

export const mutations = {
    setActiveChannelTab(state, payload) {
        state.activeChannelTab = payload;
    },
    setChannelData(state, payload) {
        state[payload.channel] = payload.data;
    },
    setCurrentJazzyLiveShow(state, payload) {
        state.currentJazzyLiveShow = payload;
    },
    setCurrentJazzyLiveShowShID(state, payload) {
        state.currentJazzyLiveShowShID = payload;
    },
};
