import createPersistedState from 'vuex-persistedstate';

// Store paths defined here will be saved in cookies, persisted data will be visible on both client and server-side.
// Keep in mind that the combined size of the cookies can not exceed 4Kb.
// If it does but client only storage is fine, persist your data in persisted-state.client.js instead.
// More info:  https://www.npmjs.com/package/vuex-persistedstate

export default ({ store, $cookies }) => {
    createPersistedState({
        paths: ['channel.activeChannelTab'],
        storage: {
            getItem: (key) => {
                return $cookies.get(key);
            },
            setItem: (key, value) => {
                $cookies.set(key, value);
            },
            removeItem: (key) => {
                $cookies.remove(key);
            },
        },
    })(store);
};
