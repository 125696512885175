<template>
    <footer
        class="pt-8 px-3 sm:px-10 md:pt-12 lg:pt-16 lg:pb-12 bg-grey-200"
        :class="$device.isIos ? 'pb-40' : 'pb-24'"
    >
        <div class="grid grid-cols-12 gap-5 sm:gap-y-8 lg:gap-5 mb-8 sm:mb-12 lg:mb-20">
            <div
                class="col-span-12 lg:col-span-3 flex justify-center lg:justify-start gap-3 sm:gap-4 lg:gap-3 xl:gap-4 order-3 lg:order-1 mt-2 sm:mt-0"
            >
                <a
                    href="https://www.facebook.com/90.9Jazzy/"
                    target="_blank"
                    rel="noopener noreferrer"
                    class="social-icon"
                >
                    <facebook-icon :size="22" />
                </a>
                <a
                    v-if="false"
                    href="https://example.com"
                    target="_blank"
                    rel="noopener noreferrer"
                    class="social-icon"
                >
                    <soundcloud-icon :size="22" />
                </a>
                <a
                    v-if="false"
                    href="https://example.com"
                    target="_blank"
                    rel="noopener noreferrer"
                    class="social-icon"
                >
                    <youtube-icon :size="22" />
                </a>
                <a
                    href="https://www.instagram.com/jazzyradio/"
                    target="_blank"
                    rel="noopener noreferrer"
                    class="social-icon"
                >
                    <instagram-icon :size="22" />
                </a>
                <a
                    v-if="false"
                    href="https://example.com"
                    target="_blank"
                    rel="noopener noreferrer"
                    class="social-icon"
                >
                    <twitch-icon :size="22" />
                </a>
                <a
                    v-if="false"
                    href="https://example.com"
                    target="_blank"
                    rel="noopener noreferrer"
                    class="social-icon"
                >
                    <spotify-icon :size="22" />
                </a>
            </div>

            <div class="col-span-12 sm:col-span-6 lg:col-span-6 flex items-center justify-center order-1 lg:order-2">
                <span class="font-FibraOne-UltraBold text-center">
                    SMS/Viber/WhatsApp:
                    <a href="tel:+36302010909" class="hover:underline"> +36 30 20 10 909 </a>
                </span>
            </div>
            <div
                class="col-span-12 sm:col-span-6 lg:col-span-3 flex justify-center lg:justify-end gap-4 order-2 lg:order-3"
            >
                <a href="https://apps.apple.com/app/id1578472174" target="_blank" rel="noopener noreferrer">
                    <img class="w-[90%] mx-auto" :src="require('~/assets/images/logos/appstore.svg')" alt="App Store" />
                </a>
                <a
                    href="https://play.google.com/store/apps/details?id=com.mobiroller.mobi1407959019852"
                    target="_blank"
                    rel="noopener noreferrer"
                >
                    <img
                        class="w-[90%] mx-auto"
                        :src="require('~/assets/images/logos/googleplay.svg')"
                        alt="Google Play"
                    />
                </a>
            </div>
        </div>
        <div class="grid grid-cols-12 gap-5 text-xs">
            <div class="col-span-12 xl:col-span-5 order-2 xl:order-1 flex items-center">
                <div
                    class="flex flex-col xl:flex-row flex-wrap items-center lg:items-center mb-4 xl:-mb-2 text-grey-500 w-full xl:w-auto flex-1"
                >
                    <span class="mr-2 whitespace-nowrap mb-4 xl:mb-0">&copy; {{ date }} Magyar Jazz Rádió Kft.</span>
                    <div class="flex justify-center lg:justify-start flex-wrap">
                        <nuxt-link
                            v-for="content in $store.state.data.siteContents.filter((x) => x.show_in_footer)"
                            :key="`footer-site-content-link-${content.id}`"
                            :to="`/${content.slug}`"
                            class="m-2 whitespace-nowrap"
                        >
                            {{ content.title }}
                        </nuxt-link>
                        <button class="m-2 whitespace-nowrap" @click="$store.dispatch('cookie/openBanner')">
                            Sütibeállítások
                        </button>
                    </div>
                </div>
            </div>
            <div class="col-span-12 xl:col-span-7 mb-10 xl:mb-0 order-1 xl:order-2">
                <div class="flex flex-col xl:flex-row gap-x-6 items-center">
                    <img
                        class="mix-blend-multiply w-36 mb-4 md:mb-0"
                        :src="require('~/assets/images/logos/nmh.png')"
                        alt="NMH logó"
                    />
                    <div>
                        <span class="font-FibraOne-UltraBold block mb-1 text-sm">Felügyeleti szervünk</span>
                        <div class="text-xs text-grey-500">
                            <p>
                                Nemzeti Média- és Hírközlési hatóság 1037 Budapest, Ostrom u. 23-25.
                                <a class="text-red-500 hover:underline" target="_blank" href="https://nmhh.hu/">
                                    www.nmhh.hu
                                </a>
                            </p>
                            <p>
                                Médiaszolgáltatási tevékenységét a Médiatanács a Magyar Média Mecenatúra program
                                keretében támogatja.
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </footer>
</template>

<script>
export default {
    name: 'Footer',
    computed: {
        date() {
            const now = new Date();
            return now.getFullYear();
        },
    },
};
</script>

<style scoped>
.social-icon {
    @apply inline-flex items-center justify-center w-10 h-10 bg-grey-700 rounded-full transition-colors text-white border border-white;
    @media (hover: hover) {
        &:hover {
            @apply bg-white text-grey-700 border-grey-700;
        }
    }
    &:active {
        @apply bg-grey-200;
    }
}
</style>
