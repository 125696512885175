<template>
    <span>
        <span class="inline">
            <span
                v-if="$store.state.channel.currentJazzyLiveShow.show.slug === slug"
                class="w-2 h-2 min-w-[8px] min-h-[8px] mr-1 mb-0.5 rounded-full bg-red-500"
            ></span>
        </span>
        <span class="inline"> {{ title }}</span>
    </span>
</template>

<script>
export default {
    name: 'MenuShowListItemInner',
    props: {
        slug: {
            type: String,
            required: true,
        },
        title: {
            type: String,
            required: true,
        },
    },
};
</script>

<style scoped></style>
