export default ({ app }, inject) => {
    inject('scrollToElement', (selector, offset = 150, mode = 'smooth') => {
        const targetScrollY = document.querySelector(selector).getBoundingClientRect().top + window.scrollY - offset;

        if ('scrollBehavior' in document.documentElement.style) {
            window.scrollTo({ left: 0, top: targetScrollY, behavior: mode });
        } else {
            window.scrollTo(0, targetScrollY);
        }
    });
    inject('downloadFile', (url, fileName) => {
        fetch(url, { method: 'get', mode: 'no-cors', referrerPolicy: 'no-referrer' })
            .then((res) => res.blob())
            .then((res) => {
                const aElement = document.createElement('a');
                aElement.setAttribute('download', fileName);
                const href = URL.createObjectURL(res);
                aElement.href = href;
                aElement.setAttribute('target', '_blank');
                aElement.click();
                URL.revokeObjectURL(href);
            });
    });
    inject('getChannelFallbackImage', (e, channel, size = 'small') => {
        switch (channel) {
            case 'jazzycool':
                e.target.src =
                    size === 'small'
                        ? require('~/assets/images/placeholders/jazzy-thumbnail-fallback-cool.jpg')
                        : require('~/assets/images/placeholders/jazzy-cover-fallback-cool.jpg');
                break;
            case 'jazzygroove':
                e.target.src =
                    size === 'small'
                        ? require('~/assets/images/placeholders/jazzy-thumbnail-fallback-groove.jpg')
                        : require('~/assets/images/placeholders/jazzy-cover-fallback-groove.jpg');
                break;
            case 'jazzysoul':
                e.target.src =
                    size === 'small'
                        ? require('~/assets/images/placeholders/jazzy-thumbnail-fallback-soul.jpg')
                        : require('~/assets/images/placeholders/jazzy-cover-fallback-soul.jpg');
                break;
            default:
                e.target.src = require('~/assets/images/placeholders/jazzy-thumbnail-fallback_transparent.png');
        }
    });
};
