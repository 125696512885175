export default async function ({ $axios, store, error }) {
    // requests were moved here from store/index nuxtServerInit method
    // so that we can work with the nuxt error method

    if (store.state.channel.jazzy) {
        return;
    }

    await Promise.allSettled([
        $axios.$get('/'),
        $axios.$get('/show'),
        $axios.$get('/article'),
        $axios.$get('/content'),
        $axios.$get('/promotion-location'),
        $axios.$get('https://radio.musorok.org/api/nowplaying/jazzy'),
        $axios.$get('https://radio.musorok.org/api/nowplaying/jazzycool'),
        $axios.$get('https://radio.musorok.org/api/nowplaying/jazzygroove'),
        $axios.$get('https://radio.musorok.org/api/nowplaying/jazzysoul'),
    ])
        .then(
            ([
                coreRes,
                showsRes,
                articleRes,
                siteContentRes,
                promotionRes,
                jazzyLiveRes,
                jazzyCoolRes,
                jazzyGrooveRes,
                jazzySoulRes,
            ]) => {
                if (coreRes.status === 'fulfilled' && coreRes.value.success === 1) {
                    store.commit('data/setScheduleByDays', coreRes.value.data.schedule);
                    store.commit('data/setHighlightedShows', coreRes.value.data.highlighted_shows);
                    store.commit('data/setQuote', coreRes.value.data.quote);
                    if (coreRes.value.data.current) {
                        store.commit('channel/setCurrentJazzyLiveShow', coreRes.value.data.current);
                    }
                } else {
                    throw new Error(coreRes.reason.message);
                }

                if (showsRes.status === 'fulfilled' && showsRes.value.success === 1) {
                    store.commit(
                        'data/setShows',
                        showsRes.value.data.sort((a, b) => a.title.localeCompare(b.title))
                    );
                } else {
                    throw new Error(showsRes.reason.message);
                }

                if (articleRes.status === 'fulfilled' && articleRes.value.success === 1) {
                    store.commit('data/setArticles', articleRes.value.data);
                } else {
                    throw new Error(articleRes.reason.message);
                }

                if (siteContentRes.status === 'fulfilled' && siteContentRes.value.success === 1) {
                    store.commit('data/setSiteContents', siteContentRes.value.data);
                } else {
                    throw new Error(siteContentRes.reason.message);
                }

                if (promotionRes.status === 'fulfilled' && promotionRes.value.success === 1) {
                    store.commit('data/setPromotions', promotionRes.value.data);
                } else {
                    throw new Error(promotionRes.reason.message);
                }

                if (jazzyLiveRes.status === 'fulfilled') {
                    store.commit('channel/setChannelData', { channel: 'jazzy', data: jazzyLiveRes.value });
                } else {
                    store.commit('globals/setIsGenericErrorMessageVisible', true);
                    // throw new Error(jazzyLiveRes.reason.message);
                }

                if (jazzyCoolRes.status === 'fulfilled') {
                    store.commit('channel/setChannelData', { channel: 'jazzycool', data: jazzyCoolRes.value });
                } else {
                    store.commit('globals/setIsGenericErrorMessageVisible', true);
                    // throw new Error(jazzyCoolRes.reason.message);
                }

                if (jazzyGrooveRes.status === 'fulfilled') {
                    store.commit('channel/setChannelData', { channel: 'jazzygroove', data: jazzyGrooveRes.value });
                } else {
                    store.commit('globals/setIsGenericErrorMessageVisible', true);
                    // throw new Error(jazzyGrooveRes.reason.message);
                }

                if (jazzySoulRes.status === 'fulfilled') {
                    store.commit('channel/setChannelData', { channel: 'jazzysoul', data: jazzySoulRes.value });
                } else {
                    store.commit('globals/setIsGenericErrorMessageVisible', true);
                    // throw new Error(jazzySoulRes.reason.message);
                }
            }
        )
        .catch((err) => {
            console.log(err.message);
            return error({ message: err.message });
        });

    if (!store.state.channel.currentJazzyLiveShow) {
        await store.dispatch('setFallbackDataForCurrentJazzyLiveShow').catch((err) => {
            console.log(err);
            return error({ message: 'Sajnáljuk, szerverhiba történt' });
        });
    }
}
